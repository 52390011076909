import { firebaseAuth } from './firebase.service';
import {
  OAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  User,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithCustomToken,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import {
  set as setLocalStorage,
  get as getLocalStorage,
  remove as removeLocalStorage,
} from '../local-storage';
import {
  addParamToUrl,
  copyQuerystring,
  getDomain,
  getUrlQueryParam,
  makeQueryString,
} from '../url-helpers';
import axios, { AxiosError } from 'axios';
import Environment, { isProd } from '../environment';
import { ApiResponse, handleResponse } from './index';
import { JsonObject, UiOption } from '../helpers';
import { getUnixTime } from 'date-fns';

export type AuthSite = 'partner' | 'admin' | 'portal';

export type AuthType = 'OIDC' | 'password' | 'magicLink' | 'SAML';

export const SupportedAuthTypes: AuthType[] = ['OIDC', 'magicLink', 'SAML'];

export type AuthMethod = {
  type: AuthType;
  name: string;
  logo?: string;
  email: string;
  password?: string;
  tenantId: string;
  providerId: string;
};

export type RootRole = 'root:owner' | 'root:admin';

export type AuthRole = 'org:owner' | 'org:admin' | 'org:auditor' | 'org:user';

export type AuthRolePartner = 'partner:owner' | 'partner:admin' | 'partner:auditor';

export const RoleOpts: UiOption[] = [
  { label: 'Owner', value: 'org:owner' },
  { label: 'Admin', value: 'org:admin' },
  { label: 'Auditor', value: 'org:auditor' },
  { label: 'User', value: 'org:user' },

  { label: 'Owner', value: 'partner:owner' },
  { label: 'Admin', value: 'partner:admin' },
  { label: 'Auditor', value: 'partner:auditor' },

  { label: 'Root Owner', value: 'root:owner' },
  { label: 'Root Admin', value: 'root:admin' },
  { label: 'Root Auditor', value: 'root:auditor' },
];

export type AuthUser = User & {
  token: string;
  orgId: string;
  role: AuthRole | null;
  partnerId: string;
  partnerRole: AuthRolePartner | null;
  rootRole: RootRole | null;
};

export enum AuthCheckErrorCode {
  TENANT_EXPIRED = 'tenant-expired',
}

export type AuthCheckResult = {
  user: AuthUser | null; // the authenticated user found during the check
  error: string; // if the method being checked is active, but did not succeed, the reason why
  code?: AuthCheckErrorCode; // if the method being checked failed, the error code from firebase or elsewhere
  method: AuthType | null; // whether or not the method being checked is active
};

export type RedirectParams = {
  redirect_to?: string;
  auth_type?: string;
};

const LINK_STORAGE_KEY = 'sp-email-link';

class AuthProviderModel {
  user: AuthUser | null = null;

  get token(): string {
    return this.user?.token || '';
  }

  get orgId(): string {
    return this.user?.orgId || '';
  }

  get partnerId(): string {
    return this.user?.partnerId || '';
  }
}

export const AuthProvider = new AuthProviderModel();

const axiosClient = axios.create({
  baseURL: '',
  timeout: 10000,
  withCredentials: false,
  responseType: 'json',
  headers: {
    'X-Requested-By': 'surepath',
  },
});

axiosClient.interceptors.request.use((config) => {
  if (AuthProvider.token) {
    config.headers['Surepath-Authorization'] = `Bearer ${AuthProvider.token}`;
  }

  return config;
});

const post = async (path: string, data: JsonObject): Promise<ApiResponse> => {
  return axiosClient
    .post(path, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(handleResponse);
};

const get = async (path: string, params?: JsonObject): Promise<ApiResponse> => {
  return axiosClient.get(path, { params }).then(handleResponse);
};

export const getRoleLabel = (user: AuthUser): string => {
  const { rootRole, role, partnerRole } = user;
  const compRole = rootRole || partnerRole || role;
  return RoleOpts.find(({ value }) => value === compRole)?.label || '';
};

export const getSiteUrl = (site: AuthSite): string => {
  if (isProd()) {
    return `https://${site}.surepath.ai`;
  }

  const envPart = String(window.location.hostname).split('.')[1];

  if (!['local', 'dev', 'stage'].includes(envPart)) {
    return '';
  }

  return `https://${site}.${envPart}.surepath.ai`;
};

export const getSite = (url?: string): AuthSite | null => {
  const siteUrl = url || getRedirectUrl();

  if (!siteUrl) {
    return null;
  }

  try {
    const url = new URL(siteUrl);
    const site = url.hostname.split('.').shift() || '';

    if (!['admin', 'partner', 'portal'].includes(site)) {
      return null;
    }

    return site as AuthSite;
  } catch {
    console.warn('invalid site url', url);
  }

  return null;
};

const isPartnerUrl = (url: string): boolean => {
  return !!String(url).match('^https?://partner.((dev|stage|local).)?surepath.ai');
};

const isPartnerRedirect = (): boolean => {
  let redirectUrl = getUrlQueryParam('redirect_to');

  // check for magic link redirect
  if (!redirectUrl) {
    const continueUrl = getUrlQueryParam('continueUrl');

    if (continueUrl) {
      redirectUrl = getUrlQueryParam('redirect_to', undefined, continueUrl);
    }
  }

  if (!redirectUrl) {
    return false;
  }

  return isPartnerUrl(redirectUrl);
};

export const getRedirectUrl = (interceptId?: string): string => {
  const { redirect_to, auth_type } = getRedirectParams();
  let redirectUrl = redirect_to;

  // @todo validate?
  if (!redirectUrl) {
    return '';
  }

  if (auth_type === 'int' && interceptId) {
    redirectUrl = addParamToUrl(redirectUrl, Environment.SP_GATEWAY_QUERY_PARAM, interceptId);
  }

  return redirectUrl;
};

export const getRedirectParams = (): RedirectParams => {
  const params: RedirectParams = {};
  const destination = getUrlQueryParam('redirect_to');
  if (destination) {
    params.redirect_to = addFromAuthSiteParam(destination);
  }
  const authType = getUrlQueryParam('auth_type') || '';
  if (['session', 'int'].includes(authType)) {
    params.auth_type = authType;
  }

  return params;
};

const addFromAuthSiteParam = (destination: string) => {
  if (!String(getDomain(destination)).match(/^.+\.surepath\.ai$/)) {
    return destination;
  }
  return addParamToUrl(destination, 'fas', 1);
};

export const signOut = async (): Promise<boolean> => {
  // this will throw if the origin jwt is expired, or the user is not authenticated
  try {
    await get('/auth/logout');
  } catch (err) {
    console.warn(err);
  }

  try {
    await firebaseAuth.signOut();

    // ensure AuthProvider is immediately in sync with firebase
    AuthProvider.user = null;

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const refreshSessionCookie = async (): Promise<string> => {
  const { auth_type } = getRedirectParams();

  try {
    const isIntercept = Boolean(auth_type === 'int');

    const response = await post('/auth/login', {
      serviceType: isIntercept ? 'intercept' : 'session',
    });

    if (isIntercept) {
      const { interceptId } = (response as { interceptId: string }) || {};
      if (interceptId) {
        return interceptId;
      }
    }
  } catch (err) {
    console.error(err);
  }

  return '';
};

export const getAuthMethods = async (email: string): Promise<AuthMethod[]> => {
  try {
    const response = await post('/auth/hrd', { email, partner: isPartnerRedirect() });

    if (!Array.isArray(response)) {
      return [];
    }

    return response
      .filter(({ type }) => SupportedAuthTypes.includes(type as AuthType))
      .map(({ id, name, tenantId, type }) => ({
        type: type as AuthType,
        name: type === 'magicLink' ? 'Magic Link' : (name as string),
        email,
        tenantId: tenantId as string,
        providerId: id as string,
      }));
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const execAuthMethod = async (authMethod: AuthMethod): Promise<AuthUser | null | string> => {
  const { email, password = '', tenantId, providerId, type } = authMethod;

  firebaseAuth.tenantId = tenantId;

  const redirectParams = getRedirectParams();
  const defaultErrorMessage = 'There was an internal error';

  switch (type) {
    case 'OIDC':
    case 'SAML': {
      const provider = new OAuthProvider(providerId);

      provider.setCustomParameters({
        login_hint: email,
        ...redirectParams,
      });

      signInWithRedirect(firebaseAuth, provider);
      return null;
    }

    case 'password':
      try {
        const credential = await signInWithEmailAndPassword(firebaseAuth, email, password);
        if (!credential?.user) {
          return defaultErrorMessage;
        }

        return credential.user as AuthUser;
      } catch (err) {
        console.error(err);
        // let's go ahead and NOT use firebase-provided constants here
        // https://firebase.google.com/docs/reference/js/auth#autherrorcodes
        const code = (err as FirebaseError).code;
        let message = defaultErrorMessage;
        if (['auth/wrong-password', 'auth/invalid-credential'].includes(code)) {
          message = 'That login was not found';
        }
        return message;
      }

    case 'magicLink':
      try {
        const continueUrl = `${window.location.origin}?${makeQueryString(redirectParams)}`;
        await post('/auth/magicLink', { email, continueUrl, partner: isPartnerRedirect() });

        setLocalStorage(LINK_STORAGE_KEY, email);

        return null;
      } catch (err) {
        console.error(err);
        return defaultErrorMessage;
      }
  }
};

/*
 * The Firebase auth function getRedirectResult will continue to return the authenticated user, even after
 * calling signOut. There is no way to "clear" the value returned. So we either need to keep track of this
 * or do a hard page refresh.
 *
 * https://github.com/firebase/firebase-js-sdk/issues/133
 * https://stackoverflow.com/questions/39089901/firebase-getredirectresult-is-being-called-after-logout
 */
let authRedirected = false;

export const checkAuthRedirect = async (): Promise<AuthCheckResult> => {
  const response: AuthCheckResult = { user: null, error: '', method: null };

  if (authRedirected) {
    return response;
  }
  try {
    const result = await getRedirectResult(firebaseAuth);

    // no redirect found
    if (result === null) {
      return response;
    }

    authRedirected = true;
    response.method = 'OIDC';

    // user missing
    if (!result?.user) {
      response.error = 'The user was not found';
      return response;
    }

    const authUser = await getAuthUser(result.user);

    if (!authUser) {
      response.error = 'The user is invalid';
      return response;
    }

    response.user = authUser;
    return response;
  } catch (err) {
    response.code = getAuthCheckCodeFromError(err as FirebaseError);
    response.error = 'There was an authentication error';
    return response;
  }
};

export const getMagicLinkEmail = (): string => (getLocalStorage(LINK_STORAGE_KEY) as string) || '';

export const checkAuthLink = async (forceEmail?: string): Promise<AuthCheckResult> => {
  const response: AuthCheckResult = { user: null, error: '', method: null };

  try {
    const hasLink = isSignInWithEmailLink(firebaseAuth, window.location.href);

    if (!hasLink) {
      return response;
    }

    response.method = 'magicLink';

    const email = forceEmail || getMagicLinkEmail();

    if (!email) {
      response.error = 'Could not match email address';
      return response;
    }

    // lookup tenant id via email
    const authMethods = await getAuthMethods(email);
    const linkMethod = authMethods.find(({ type }) => type === 'magicLink');

    if (!linkMethod) {
      response.error = 'Could not find provider';
      return response;
    }

    firebaseAuth.tenantId = linkMethod.tenantId;

    const result = await signInWithEmailLink(firebaseAuth, email, window.location.href);

    // user missing
    if (!result?.user) {
      response.error = 'The user was not found';
      return response;
    }

    const authUser = await getAuthUser(result.user);

    if (!authUser) {
      response.error = 'The user is invalid';
      return response;
    }

    removeLocalStorage(LINK_STORAGE_KEY);

    // pull redirect params from the continue URL and append them to the querystring so they
    // can be consumed after the user is fully authenticated and signed in
    const continueParam = getUrlQueryParam('continueUrl');
    if (continueParam) {
      copyQuerystring(continueParam);
    }

    response.user = authUser;
    return response;
  } catch (err) {
    response.code = getAuthCheckCodeFromError(err as FirebaseError);
    response.error = 'There was an authentication error';
    removeLocalStorage(LINK_STORAGE_KEY);
    return response;
  }
};

export const getCurrentUser = async (): Promise<AuthUser | null> => {
  try {
    const fbAuthUser = await getCurrentFirebaseUser(true);

    if (fbAuthUser) {
      console.log('auth from local');
      return fbAuthUser;
    }

    const response = await get('/auth/status');
    const { accessToken, tenantId } = (response as { accessToken: string; tenantId: string }) || {};

    if (!accessToken || !tenantId) {
      return null;
    }

    // @todo decode and validate access token client-side slightly more secure?

    firebaseAuth.tenantId = tenantId;

    const result = await signInWithCustomToken(firebaseAuth, accessToken);

    if (!result?.user) {
      return null;
    }

    return getAuthUser(result.user);
  } catch (err) {
    const axiosError = err as AxiosError;
    // cookie is probably missing
    if (axiosError.response?.status === 400) {
      return null;
    }
    console.error(err);
    return null;
  }
};

/*
 * Ensure that the JWT token is refreshed periodically so that other, non-firebase services can always
 * have an up-to-date token. Note that while Firebase will lazy-refresh the token for itself, other
 * services (like AtlasDataAPI) need a synchronous way of referencing an up-to-date token.
 *
 * While it seems as though you can listen for auth token expiration using onIdTokenChanged, this handler does not
 * actually fire when or before that happens:
 * https://github.com/firebase/firebase-js-sdk/issues/2985
 * https://github.com/firebase/firebase-js-sdk/blob/e9ff107eedbb9ec695ddc35e45bdd62734735674/packages/auth/src/core/index.ts#L136
 *
 */
export const observeTokenChange = () => {
  firebaseAuth.onIdTokenChanged((user: User) => {
    console.log('local auth change', user);
    if (!user) {
      AuthProvider.user = null;
      return;
    }

    getAuthUser(user).then((authUser) => {
      AuthProvider.user = authUser;
    });
  });

  const intMinutes = 1000 * 60 * 8;

  setInterval(() => {
    firebaseAuth.currentUser?.getIdTokenResult().then((result) => {
      const expTime = result?.claims?.exp;
      if (!expTime) {
        return;
      }

      const minuteDiff = (Number(expTime) - getUnixTime(new Date())) / 60;

      if (minuteDiff < 20) {
        firebaseAuth.currentUser?.getIdToken(true);
      }
    });
  }, intMinutes);
};

export const checkAuth = async (): Promise<AuthCheckResult> => {
  // check for redirected from sso, the most-common login method
  const {
    user: redirectUser,
    error: redirectError,
    code: redirectCode,
    method: redirectMethod,
  } = await checkAuthRedirect();

  if (redirectCode) {
    return { user: null, error: redirectError, code: redirectCode, method: null };
  }

  if (redirectMethod) {
    console.log('auth from redirect');
    let user: AuthUser | null = null;
    let error = '';

    if (redirectError || !redirectUser) {
      error = redirectError || 'Unable to sign in';
    } else {
      user = redirectUser;
    }

    AuthProvider.user = user;

    return { user, error, method: redirectMethod };
  }

  // check for redirect from magic link
  const {
    user: linkUser,
    error: linkError,
    code: linkCode,
    method: linkMethod,
  } = await checkAuthLink();

  if (linkCode) {
    return { user: null, error: linkError, code: linkCode, method: null };
  }

  if (linkMethod) {
    console.log('auth from link');

    let user: AuthUser | null = null;
    let error = '';

    if (linkError || !linkUser) {
      error = linkError || 'Unable to sign in';
    } else {
      user = linkUser;
    }

    AuthProvider.user = user;

    return { user, error, method: linkMethod };
  }

  // check for existing session
  console.log('auth from current');
  const currentUser = await getCurrentUser();

  AuthProvider.user = currentUser;

  return { user: currentUser, error: '', method: null };
};

const getCurrentFirebaseUser = async (forceRefresh?: boolean): Promise<AuthUser | null> => {
  return new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (!user) {
        resolve(null);
        return;
      }

      unsubscribe();

      getAuthUser(user, forceRefresh).then(resolve);
    });
  });
};

const isValidCalimValue = (claimVal: string | null | undefined): boolean =>
  !!claimVal && claimVal !== 'none';

const getAuthUser = async (user: User, forceRefresh = false): Promise<AuthUser | null> => {
  const userWithToken = user as User & { accessToken: string };

  // user missing critical info
  if (!userWithToken.tenantId || !userWithToken.accessToken) {
    return null;
  }

  const result = await user.getIdTokenResult(forceRefresh);
  const { claims, token } = result || {};

  if (!claims || !token) {
    console.error('user missing claims or token');
    return null;
  }

  const { org_id, role, partner_id, partner_role, root_role } = claims as {
    org_id: string;
    role: string;
    partner_id: string;
    partner_role: string;
    root_role: string;
    type: string;
  };

  const hasOrgLogin = isValidCalimValue(org_id) && isValidCalimValue(role);
  const hasRootLogin = isValidCalimValue(root_role);
  const hasPartnerLogin =
    isValidCalimValue(partner_id) && (isValidCalimValue(partner_role) || hasRootLogin);

  if (!hasOrgLogin && !hasPartnerLogin) {
    console.error('user missing valid login', claims);
    return null;
  }

  const authUser: AuthUser = {
    ...user,
    orgId: '',
    role: null,
    partnerId: '',
    partnerRole: null,
    rootRole: null,
    token,
  };

  if (hasOrgLogin) {
    authUser.orgId = org_id;
    authUser.role = role as AuthRole;
  }

  if (hasPartnerLogin) {
    authUser.partnerId = partner_id;
    authUser.partnerRole = partner_role as AuthRolePartner;
  }

  if (hasRootLogin) {
    authUser.rootRole = root_role as RootRole;
  }

  return authUser;
};

const getAuthCheckCodeFromError = (error: FirebaseError): AuthCheckErrorCode | undefined => {
  const { code, message } = error || {};
  if (code === 'auth/internal-error' && String(message).includes('tenant-expired')) {
    return AuthCheckErrorCode.TENANT_EXPIRED;
  }
};
