import CopyClipButton from '@/components/copy-clip-button';
import Text from '@/components/text';
import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import MessageFooter from './message-footer';

interface Props {
  emailAddress: string;
  description?: string;
}
const SupportEmail: FC<Props> = ({ emailAddress, description }) => {
  const hasSupportEmail = !!emailAddress;
  const hasDescription = !!description;

  return (
    <MessageFooter>
      <Box>
        {hasDescription ? description : 'Please contact your IT Support'}
        {hasSupportEmail ? ', or send an email to' : '.'}
      </Box>

      {hasSupportEmail && (
        <Stack direction="row" gap={1} alignItems="center">
          <Text bold>{emailAddress}</Text>
          <CopyClipButton
            value={emailAddress}
            color="black"
            size="small"
            message="Support email copied to clipboard"
          />
        </Stack>
      )}
    </MessageFooter>
  );
};

export default SupportEmail;
