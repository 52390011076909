import { Stack, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactNode;
}

const useStyles = createUseStyles((theme: Theme) => ({
  messageFooter: {
    marginTop: 32,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    paddingTop: 16,
  },
}));

const MessageFooter: FC<Props> = ({ children }) => {
  const styles = useStyles();
  return (
    <Stack gap={1} className={styles.messageFooter}>
      {children}
    </Stack>
  );
};

export default MessageFooter;
